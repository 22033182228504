<template>
  <div class="body">
    <!-- <div class="main-grid">
    <div class="height">
      <img class="flags left" src="./../assets/flags.svg" alt="">
      <img class="flags flags1 right" src="./../assets/flags.svg" alt="">

      <img class="small-tent left" src="./../assets/smallTent.svg" alt="">
      <img class="small-tent small-tent1 right" src="./../assets/smallTent1.svg" alt="">
      <img class="big-tent" src="./../assets/bigTent.svg" alt="">


      <div class="logo">
        <img class="bubble wobble" src="./../assets/bubble.svg" alt="">
        <img class="bomb zoom-bomb" src="./../assets/bomb.svg" alt="">

        <img class="juggle1 zoom-juggle1" src="./../assets/juggle.svg" alt="">
        <img class="madness zoom-madness" src="./../assets/madness.png" alt="">
        <img class="juggle2 zoom-juggle2" src="./../assets/juggle.svg" alt="">

        <img class="ball1 zoom-ball1" src="./../assets/ball1.svg" alt="">
        <img class="ball2 zoom-ball2" src="./../assets/ball2.svg" alt="">

      </div>
      <div class="button-wrap">
        <button class="play">Coming Soon</button>
      </div>
    </div>
    </div>-->
    <div class="ann">
      <!-- <img class="annface" src="../assets/annachronist/annface.png" alt=""> -->
      <img class="annlogo" src="../assets/annachronist/annlogo.png" alt />
      <div class="anndownload">
        <a class="anndownloadbutton" href="https://store.steampowered.com/app/1152580/Ann_Achronist_Many_Happy_Returns/">
          <img src="../assets/annachronist/steam-logo-color.png" alt />
          Download Now
        </a>
      </div>
    </div>
    <div class="hello">
      <!-- <h1>Maybe Later Games</h1> -->
      <!-- <h2>Under Construction</h2> -->
      <!-- <p>Fun games coming soon!</p> -->
      <h1 class="h1">Game Tools</h1>
      <ul class="box-list">
        <li>
          <article>
            <router-link :to="{ name: 'sls-home'}">
              <div>
                <img src="./../tools/slaythespire/assets/metadata/main.png" alt />
              </div>
              <div class="text">
                <h1 class="title-sls">SpireSpy</h1>
                <p>Slay The Spire tier list and deck builder.</p>
              </div>
            </router-link>
          </article>
        </li>
        <!-- <li>
          <article>
            <router-link :to="{ name: 'tft-home'}">
              <div>
                <img src="./../tools/league/assets/socialmedia/square.png" alt />
              </div>
              <div class="text">
                <h1 class="title-sls">TFTChamp</h1>
                <p>Teamfight Tactics tools and tier lists.</p>
              </div>
            </router-link>
          </article>
        </li> -->
        <!-- <li> -->
        <!-- <article> -->
        <!-- <router-link :to="{ name: 'sls-home'}"> -->
        <!-- <div >
              <img src="./../assets/curtain.png" alt="">
            </div>
            <div class="text">
              <h1 class="title-jjm">Juggle Juggle Madness</h1>
              <p>Mobile game coming soon..</p>
        </div>-->
        <!-- </router-link> -->
        <!-- </article> -->
        <!-- </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",
  data() {
    return {};
  }
};
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// @import url("https://use.typekit.net/kpj2pou.css");
// @import url('https://fonts.googleapis.com/css?family=Jomhuria');

.body {
  background: hsl(209, 50%, 8%);
  // padding: 0 30px 50px;
  @media (min-width: 800px) {
    padding: 0 0 0;
  }
  min-height: 100%;
}

img {
  display: block;
}

h1,
h2,
p {
  font-weight: normal;
  // text-align: center;
  font-family: "houschka-rounded";
  font-display: swap;

}

h1,
h2 {
  font-weight: bold;
}

h1 {
  font-size: 24px;
}

.h1 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  margin-top: 80px;
}

ul {
  list-style: none;
}

li {
}

a {
  // color: #42b983;
  text-decoration: none;
}
.hello {
  max-width: 1200px;
  margin: auto;
}

.box-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;
  > li {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    overflow: hidden;
    margin: 20px;
    transition: 0.25s ease-in-out;
    background: #fff;

    @media (min-width: 660px) {
      width: 40%;
      max-width: 360px;
    }

    &:hover {
      transform: scale(1.05);
      .text {
        transform: translateY(-20px);
      }
    }
    // .img-curtain {
    //   background: #fff;
    //   // transform: scale(1.05);
    // }
    .text {
      padding: 14px 22px;
      background: #fff;
      transition: 0.25s ease-in-out;

      h1 {
        margin: 4px 0 8px;
      }
      p {
        color: #888;
        margin: 4px 0 6px;
      }
      .title-sls {
        color: rgb(211, 47, 18);
      }
      .title-jjm {
        color: #583a6f;
      }
    }
  }
}

.main-grid {
  display: grid;
  grid-template-columns: 1;
  .height {
    min-height: 90vh;
    background-image: url("./../assets/backgroundSky.png");
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media (min-width: 800px) {
      min-height: 100vh;
    }
    .logo {
      display: block;
      position: absolute;
      width: 90%;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      @media (min-width: 800px) {
        left: 50%;
        top: 8%;
        width: 44%;
      }
      // transform: translateX(-50%);
    }
  }

  .juggle1 {
    position: absolute;
    top: 0%;
    left: 16%;
    width: 32%;
  }

  .juggle2 {
    position: absolute;
    top: 19%;
    left: 28%;
    width: 55%;
  }

  .madness {
    position: absolute;
    width: 75%;
    bottom: 14%;
    right: 5%;
  }

  .ball1 {
    position: absolute;
    width: 21%;
    top: -9%;
    left: 43%;
  }

  .ball2 {
    position: absolute;
    width: 20%;
    bottom: 6%;
    right: 10%;
  }

  .bomb {
    position: absolute;
    width: 21%;
    top: 20%;
    left: 5%;
  }

  .big-tent {
    position: absolute;
    bottom: -5%;
    transform: scale(1.05);
    // width: 100%;
    // width: 1500px;
  }
  .small-tent {
    position: absolute;
    bottom: -3%;
    width: 25%;
  }
  .small-tent1 {
    width: 15%;
    bottom: -3%;
  }
  .flags {
    position: absolute;
    top: 0;
    width: 25%;
  }
  .flags1 {
    transform: scaleX(-1);
  }

  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .button-wrap {
    display: block;
    position: absolute;
    left: 0;
    bottom: 16%;
    width: 100%;
    text-align: center;
    .play {
      /* Rectangle 7: */
      box-shadow: 0 14px 0 -4px rgba(27, 2, 43, 0.5),
        inset 12px -12px 0 0 #cf3c56;
      background: #ea455e;
      border-radius: 100px;
      border: none;
      height: 100px;
      // width: 20%;
      transform: scale(1, 1);
      color: #fff;
      font-size: 68px;
      font-family: "Jomhuria", cursive;
      font-display: swap;
      padding: 10px 50px 0 50px;
      transition: 0.5s;
      outline: none;
      &:hover {
        transform: scale(1.1, 1.1);
        box-shadow: 0 44px 0 -20px rgba(27, 2, 43, 0.5),
          inset 6px -6px 0 0 rgb(212, 62, 90);
        background: rgb(247, 68, 95);
      }
    }
  }
}

.footer-title {
  color: #fff;
  text-align: center;
  padding: 50px;
  background: rgb(15, 15, 15);
}

@-webkit-keyframes wobble {
  0% {
    transform: rotate(0deg) scale(0.98, 1.08);
  }
  20% {
    transform: rotate(-2deg) scale(1.02, 0.96);
  }
  40% {
    transform: rotate(1deg) scale(1.02, 1.02);
  }
  60% {
    transform: rotate(3deg) scale(0.98, 0.98);
  }
  80% {
    transform: rotate(1deg) scale(0.95, 1.04);
  }
  100% {
    transform: rotate(0deg) scale(0.98, 1.08);
  }
}
.wobble {
  animation: wobble 12s infinite ease-in-out;
}

@-webkit-keyframes balls {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  20% {
    transform: rotate(-1deg) translate(-3%, 4%);
  }
  35% {
    transform: rotate(3deg) translate(3%, 0);
  }
  50% {
    transform: rotate(-1deg) translate(0, 3%);
  }
  65% {
    transform: rotate(3deg) translate(-5%, -3%);
  }
  80% {
    transform: rotate(1deg) translate(-2%, 1%);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

.zoom-ball1 {
  animation: balls 14s infinite ease-in-out;
}
.zoom-ball2 {
  animation: balls 15s infinite ease-in-out;
}
.zoom-bomb {
  animation: balls 16s infinite ease-in-out;
}

// @-webkit-keyframes zoom-juggle1  {
//   0%  { transform:  rotate(0deg) scale(0.98, 1.08); }
//   20%  { transform:  rotate(-2deg) scale(1.02, 0.96); }
//   40%  { transform:  rotate(1deg) scale(1.02, 1.02); }
//   60%  { transform:  rotate(3deg) scale(0.98, 0.98); }
//   80%  { transform:  rotate(1deg) scale(0.95, 1.04); }
// }

// @-webkit-keyframes zoom-juggle1  {
//   0%  { transform:  rotate(0deg) scale(0.98, 1.08) rotate3d(0, 0 , 1, 0deg); }
//   80%  { transform:  rotate(0deg) scale(0.98, 1.08) rotate3d(0, 0 , 1, 0deg); }
//   85%  { transform:  rotate(-2deg) scale(1.02, 0.96) rotate3d(0, 0 , 1, 360deg); }
//   40%  { transform:  rotate(1deg) scale(1.02, 1.02) rotate3d(0, 0 , 1, 0deg); }
//   60%  { transform:  rotate(3deg) scale(0.98, 0.98) rotate3d(0, 0 , 1, 0deg); }
//   80%  { transform:  rotate(1deg) scale(0.95, 1.04) rotate3d(0, 0 , 1, 0deg); }
// }

@-webkit-keyframes zoom-juggle1 {
  0% {
    transform: scale(1, 1);
  }
  70% {
    transform: scale(1, 1);
  }
  74% {
    transform: scale(1.2, 1.2);
  }
  78% {
    transform: scale(0.95, 0.95);
  }
  82% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-juggle2 {
  0% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1, 1);
  }
  79% {
    transform: scale(1.2, 1.2);
  }
  83% {
    transform: scale(0.95, 0.95);
  }
  87% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-madness {
  0% {
    transform: scale(1, 1);
  }
  81% {
    transform: scale(1, 1);
  }
  85% {
    transform: scale(1.2, 1.2);
  }
  89% {
    transform: scale(0.95, 0.95);
  }
  93% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoom-juggle1 {
  animation: zoom-juggle1 20s infinite ease-in-out;
}
.zoom-juggle2 {
  animation: zoom-juggle2 20s infinite ease-in-out;
}
.zoom-madness {
  animation: zoom-madness 20s infinite ease-in-out;
}

// ann
.ann {
  background: #111;
  background: linear-gradient(
      180deg,
      rgba(#9c4fff, 0.1) 40%,
      rgba(#4c267d, 0.6) 70%,
      #000 100%
    ),
    url("../assets/annachronist/annface.png"),
    url("../assets/annachronist/background.jpg");
  background-size: cover, contain;
  background-position: center, bottom;
  background-repeat: no-repeat;
  position: relative;
}

// .ann::after {
//     content: ""; // ::before and ::after both require content
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: linear-gradient(120deg,#eaee44 50%, #33d0ff 100%);
//     opacity: .7;
// }

.annface {
  max-width: 700px;
  margin: auto;
}

.annlogo {
  max-width: 700px;
  margin: auto;
  width: 100%;
}

.anndownload {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.anndownloadbutton {
  background: linear-gradient(180deg,rgb(153, 53, 230), rgb(133, 47, 199), rgb(103, 34, 156) ) ;
  color: #fff;
  padding: 12px 24px 12px 14px;
  font-size: 20px;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  border-radius: 40px;
  margin: auto;
  font-weight: 700;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.05, 1.05);
    transition: 0.1s ease-in-out;
  }
}
</style>
